#root, html, body {
    min-height: 100%;
    margin: 0;
    padding: 0;
}

.product-view {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 350px;
}

.product-view h3 {
    font-size: 12px;
    font-weight: 600;
    color: black;
}

.product-view > div {
    margin-left: 6px;
}

.product-view > img {
    width: 55px;
}

.product-view ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.product-view ul > li {
    margin: 0;
    font-size: 12px;
}

.product-view ul > li > b {
    font-weight: 600;
}

.customer-view, .ship-view {
    padding: 0;
    margin: 0;
    list-style: none;
}

.customer-view li, .ship-view li {
    margin: 0;
    font-size: 12px;
}

.account-view {
    display: block;
    background-color: #a8a8a8;
    color: white;
    border-radius: 12px;
    text-align: center;
    padding: 0 12px;
    font-size: 12px;
    text-transform: capitalize;
}

td {
    height: 100% !important;
    vertical-align: top;
    border-bottom: 1px solid #e0e0e0 !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.status-view {
    font-size: 13px;
    width: 100px;
}

.status-view.unshipped > b {
    color: white;
    background-color: #ff5722;
    padding: 0 6px;
    text-align: center !important;
    display: inline-flex;
    border-radius: 6px;
    height: 20px;
    line-height: 20px;
    font-size: 12px !important;
    font-weight: 600;
}

.status-view.shipped > b {
    color: white;
    background-color: #c3c3c3;
    padding: 0 6px;
    text-align: center !important;
    display: inline-flex;
    border-radius: 6px;
    height: 20px;
    line-height: 20px;
    font-size: 12px !important;
    font-weight: 600;
}

.header {
    height: 32px !important;
}

div#kt_aside {
    background-color: black !important;
}

.aside .aside-primary .btn-custom.active, .aside .aside-primary .btn-custom:active, .aside .aside-primary .btn-custom:focus {
    background-color: #607d8b !important;
}

table b {
    margin-left: 3px;
}

div#kt_chat_messenger_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

div#kt_chat_messenger_footer > textarea {
    border: 1px solid #cbcbcb;
    border-radius: 6px;
    margin-right: 24px;
}

.chat-app {
    padding-left: 240px;
}

.chat-app-sidebar {
    position: absolute;
    left: 100px;
    background-color: white;
    height: 100%;
    top: 0;
    z-index: 2;
    width: 250px;
    padding: 12px 0;
}

.theads-item {
    border-bottom: 1px solid #e8e8e8;
    padding: 0 12px;
    padding-bottom: 3px;
    cursor: pointer;
    padding-top: 9px;

}

.theads-item > p {
    font-size: 11px;
    color: #999999;
    margin-bottom: 4px;
    height: 20px;
    overflow: hidden;
}

.theads-item a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
}

.theads-item a > span {
    font-size: 10px;
    color: #5e5e5e;
}

.chat-app-sidebar > select {
    width: calc(100% - 24px);
    margin: 12px;
    background-color: #f8f8f8 !important;
    text-align: center;
}

div#kt_chat_messenger_body {
    padding: 12px;
}

p.loading-text {
    text-align: center;
    font-size: 12px;
}

.theads-item.active {
    background-color: #eaeaea;
}